// table Styling start here 
@import './Color.scss';


.blanck-dev{
 height:3vh;
}
.Form-section-startup{
    background:$White-color;
    min-height:210vh;  

    .extra-div{
        background:$White-color;
        padding-top: 10px;
    }
    
    .inputs-for-responsive{
        width: 900px;
        height: 40px;
        padding: 10px;
        border-radius: 4px;
        font-size: 15px;
        border: 1px solid $Tabel-action-border-color;
    }
    .textarea-of-admistrator{
        width: 900px;
        height: 100px;
        padding: 10px;
        border-radius: 4px;
        font-size: 15px;
        border: 1px solid $Tabel-action-border-color;
    }
    .cancel-btn-of-form{
        padding: 8px 35px 8px 35px;
        border-radius: 4px;
        border: 1px solid $Tabel-action-border-color; 
        font-size: 15px;
        cursor: pointer;
        &:hover {
            background-color: #e3dede;
        }
    
    }
    .Save-btn-of-form{
        width: 130px;
        height: 39px;
        border-radius: 4px;
        border: 1px solid $btn-bg-color-save;
        background: $btn-bg-color-save;
        color: $White-color;  
        font-size: 15px;
        cursor: pointer; 
        &:hover {
            background-color: #3f44d6;
        }
    }
    .ck-Editor{
        width: 900px;
    }

}

.inputs-of-admistrator{
    width: 900px;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    font-size: 15px;
    border: 1px solid $Tabel-action-border-color;
}

// create Compaign form and also using Template Page
   
.Form-Inputs-Fields{
    input{
    //    width:600px;
       width:550px;
       height:45px;
       padding: 10px;
       border-radius:5px;
       border:1px solid $Tabel-action-border-color;
   }
   select{
    width:550px;
    height:45px;
    border-radius:5px;
    border:1px solid $Tabel-action-border-color;

   }
 
}
.ck-editor-of-compaign{
    width: 1250px;

    .ck-editor-of-compaign-border{
    border-right: 1px solid $radio-color;
    }
}

.border-of-variable{
    border:1px solid $Tabel-action-border-color;
}
.redonly-input{
    width: 1100px;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    font-size: 15px;
    border: 1px solid $Tabel-action-border-color;
}

.background-hover:hover{
    background-color: #f1efef;
}