@media (max-width: 1400px) and (min-width: 1200px) { 
    //  dashboard responsive start here
    .background-dashboard{
        margin-left: 8px!important;
    }
    .Last-week-card-section{
        width: 270px!important;
    }
    .dashboard-main-cards-div{
           width:100%;
           padding:20px;
       }
    .dash-board-cards {
    width: 286px!important;
   margin-left: 24px!important;
   justify-content: space-between;
    }
    .card-inner-main-div{
        width:100%;
    }
     .dashboard-card-hr-line {
     width: 100% !important;
     margin-left: 0px !important;
   }
    .card-left-text {
   width: 50%!important;
    h1{
     margin-left: 38px;
   }
    p{
       margin-left: 50px;
       }
    }
    .card-right-text {
        width:50%!important;
    h1{
       margin-left: 25px;
     }
    p{
     margin-left: 50px;
     }
   }
   .last-week-wraping-cards{
       flex-wrap: wrap;
       width:100%!important;
   }
   .dashboard-main-cards-div{
        margin-top: -10px;
        margin-left: -13px;
   }
    .Last-week-card-section {
     max-width:223px!important;
     height: 370px!important;
   }
   .resonsive-save-butten-cards{
       width:100px!important ;
       max-height:30px!important;
       font-size: 13px!important;
   }
   .Table-Header {
    width: 95%!important;
    margin-left: 2.5%;
    }
   //  dashboard responsive end here
       input.inputs-of-admistrator {
       width: 750px!important;
       }
       select.inputs-of-admistrator {
           width: 750px!important;
           height: 45px!important;
           // margin-left: -33px;
       }
       .textarea-of-admistrator{
           width: 750px!important;
           // margin-left: -33px;
       }
       .inputs-for-responsive{
           // margin-left: 0px;
           width: 750px!important; 
       }
       .radios{
           width:400px!important;
       }
       .radios-of-group {
           width:400px!important;
       }
       .Form-Inputs-Fields input {
           width: 400px!important;
       }
       .Form-Inputs-Fields select {
           width: 400px;
       }
       .ck-Editor{
           width:750px!important;
           // margin-left:30px;
       }
       .ck-editor-of-compaign{
           width: 750px;
           .ck-editor-of-compaign-border{
           border-right: 1px solid $radio-color;
           }
       }
       .redonly-input{
           width:750px;
       }
   }


// 1200 to 1400 Quries end here
// 1024 to 1199 media Quries start here
@media (max-width: 1199px) and (min-width: 1024px) { 
    input.inputs-of-admistrator {
        width: 600px!important;
        margin-left: -33px;
    }
select.inputs-of-admistrator {
    width: 600px!important;
    margin-left: -33px;
}
.Form-section-startup label{
    margin-left: -30px;
}
.Form-sections-startups-responsive label {
    margin-left:0px!important;
}
.inputs-for-responsive{
    margin-left: 0px;
    width: 600px!important; 
}
.textarea-of-admistrator{
    width: 600px!important;
    margin-left: -33px;
}
.Form-main-div-of-sectons.flex-row.flex-column-responsive {
    display: flex!important;
    flex-direction: column!important;
}
.radios{
    width:200px!important;
}
.radios-of-group {
    width:200px!important;
}
.ck-Editor{
    width:600px!important;
    margin-left:30px;
}
.ck-Editor label{
    margin-left:0px;
}
.ck-editor-of-compaign{
    width: 600px;
    .ck-editor-of-compaign-border{
    border-right: 1px solid $radio-color;
    }
}
.redonly-input{
    width:600px;
}
.Save-btns-of-forms-responsive{
    margin-left: -30px;
}
label.overlay-responsive-social-img.mrg-left-50.fnt-poppins {
    margin-left: 50px!important;
}
} 
// 1024 to 1199 media Quries end Here
// 992 to 1023 media Quries start here
@media (max-width: 1023px) and (min-width: 992px) { 
input.inputs-of-admistrator {
        width: 600px!important;
        margin-left: -33px;
    }
select.inputs-of-admistrator {
    width: 600px!important;
    margin-left: -33px;
}
.Form-section-startup label{
    margin-left: -30px;
}
.Form-sections-startups-responsive label {
    margin-left:0px!important;
}
.inputs-for-responsive{
    margin-left: 0px;
    width: 600px!important;
    
}
.textarea-of-admistrator{
    width: 600px!important;
    margin-left: -33px;

}
.Form-main-div-of-sectons.flex-row.flex-column-responsive {
    display: flex!important;
    flex-direction: column!important;
}
.radios{
    width:200px!important;
}
.radios-of-group {
    width:200px!important;
}
.ck-Editor{
    width:600px!important;
    margin-left:30px;
}
.ck-Editor label{
    margin-left:0px;
}
.ck-editor-of-compaign{
    width: 600px;

    .ck-editor-of-compaign-border{
    border-right: 1px solid $radio-color;
    }
}
.redonly-input{
    width:600px;
}
.Save-btns-of-forms-responsive{
    margin-left: -30px;

}
label.overlay-responsive-social-img.mrg-left-50.fnt-poppins {
    margin-left: 50px!important;
}
}
// 992 to 1023 media Quries end Here