@import './Color.scss';
@import './CreateTable.scss';
@import './Table.scss';
@import './CheckBox.scss';
@import './Radiobutton.scss';
@import './SwitchButton.scss';
@import './dashboard.scss';
@import './margin&pading.scss';
@import './Pagination.scss';
@import '../Responsive/dev2/dev2.scss';
@import '../Responsive/dev3/dev3.scss';

// font-family
.fnt-poppins{
        font-family: 'Poppins', sans-serif;
}
.white-color{
        color: $White-color;
}
.background-white{
        background: $White-color;
}
.fnt-size-20{
        font-size:20px;
}
.fnt-size-25{
        font-size:25px;
}
.fnt-size-24{
        font-size:24;
}
.fnt-size-15{
        font-size:15px;
}
.fnt-size-13{
        font-size:13px;
}
.fnt-weight-400{
        font-weight:400;
}
.fnt-weight-600{
        font-weight:600;
}
.text-center{
        text-align:center;
}
.main-dev-of-routes{
        width:100%;
}
.cursor-pointer
{
cursor: pointer;
}
// dashboard-card-data-style
.card-number-styling{
        font-size: 24px;
        font-weight: 600;
        color: black;
}
.cursor-pointer-not-allowed{
        cursor: not-allowed;
}
.width-bt-80{
        width: 80px;
}

.cancel-img{
        margin-top: 8px;
        width: 10px;
}
.back-color{
        max-width: max-content;
        margin-top: 10px;
        justify-content: space-between;
        height: 30px;
        border-radius: 8px;
        background: $Table-Background;
}       
.color-red-text{
        color: $red-color;
}
.table-data-none{
text-align: center;
}

// width 100
.width-full-100{
        width: 100%;
}