// Table-colors
$Heading-of-Header:#65696b;
$Table-Button-color:#f17a28;
$Table-navbar:#323b52;
$Table-title:#4379E9;
$White-color:#fff;
$Table-Background:#f8f9fa;
$Tabel-action-border-color:#989ca8;
$Table-text-color:#424a60;
$btn-bg-color-save:#4379E9;
$red-color:red;
// table Colors End here

// radiobtn colors
$radio-color:#D1D4DB;

// switch button color
$switch-btn-off:#b7bfc6;
// Dashboard color
$dashbrd-card-background:#FFFFFF;
$dashbrd-card-box-shadow:rgba(9,2,45,0.09);
$dashboard-card-hadr:#6b97df;
$dashboard-card-hr-vl-line:#e0e0e0;
$dashboard-card-left-right-text:#bdbdbd;