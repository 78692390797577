.routers-page-main-div-for-flex{
    width: 100%;
}
body{
    overflow: auto;
}

// inside template-page of table button css
.template-btn{
    padding: 5px!important;
}