// margin-top starts here
.mrg-top-5{
    margin-top: 5px;
}
.mrg-top-10{
    margin-top: 10px;
}
.mrg-top-20{
    margin-top: 20px;
}
.mrg-top-30{
    margin-top: 30px;
}
.mrg-top-40{
    margin-top: 40px;
}
.mrg-top-50{
    margin-top: 40px;
}.mrg-top-60{
    margin-top: 40px;
}.mrg-top-70{
    margin-top: 70px;
}

// margin-top-ends-here
//margin-left-starts here
.mrg-left-5{
    margin-left: 5px;
}
.mrg-left-10{
    margin-left: 10px;
}
.mrg-left-20{
    margin-left: 20px;
}
.mrg-left-30{
    margin-left: 30px;
}
.mrg-left-40{
    margin-left: 40px;
}
.mrg-left-45{
    margin-left: 45px;
}
.mrg-left-50{
    margin-left: 50px;
}
.mrg-left-55{
    margin-left: 55px;
}
.mrg-left-60{
    margin-left: 60px;
}
.mrg-left-70{
    margin-left: 70px;
}
// margin-left-ends-here
// padding starts from here
.pad-0{
    padding: 0px;
}