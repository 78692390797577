@import './Color.scss';

.switch-btn-of-tables{
    .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    }
   
   
   .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    background-color:$switch-btn-off;
    -webkit-transition: .4s;
    transition: .4s;
   }
   
   .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: $White-color;
    -webkit-transition: .4s;
    transition: .4s;
   }
   
   input:checked + .slider {
    background-color: $Table-title;
   }
   
   input:focus + .slider {
    box-shadow: 0 0 1px $Table-title;
   }
   
   input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
   }
}